<template>
  <!--  右键禁止-->
  <div class="monitoring" @contextmenu.prevent="handleClick">
    <div class="elevator-infos">
      <div class="video-view">
        <div>
          <video v-if="videoUrl"
                 :src="videoUrl"
                 controls
                 controlsList="nodownload"
                 disablePictureInPicture
                 ref="videoPlayer"
                 autoplay
                 style="width: 100%;height: 870px"></video>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import {mapState} from 'vuex'

export default {
  components: {
  },
  props:{
    Url:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      videoUrl:'',
      isPlayingUrl: ''
    }
  },
  beforeDestroy() {
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  watch: {},
  mounted() {

  },
  created() {
    //console.log(this.Url)
    this.videoUrl = 'file/trainingVideo/' + this.Url
  },
  methods: {
    handleClick(event) {
      event.preventDefault();
    },
    playFlv(msg) {
      if (this.isPlayingUrl !== msg.url) {
        this.$refs.videoPlayer.pause();
        this.isPlayingUrl = msg.url
        this.videoUrl = 'file/trainingVideo/' + msg.url
        this.$refs.videoPlayer.play();
      }

    },
  }
}
</script>
<style lang="scss" scoped>
.monitoring {
  padding: 12px;
}

.elevator-infos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 8px
  // margin-top: 24px;
}

.video-view {
  //background-color: white;
  width: 100%;
}

.info-view {
  background-color: white;
  width: 23%;
  padding-left: 5px;
}
</style>